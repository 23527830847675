<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 24 : 6"
      class="card-width col-lg-4 col-md-6 my-2"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="cp__title">{{ cp.code }}</v-list-item-title>
          <v-list-item-subtitle class="subtitle-1">
            <div>Pedido mínimo: {{ cp.minPrice }} €</div>
            <div>Coste envío {{ cp.price }} €</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <span
          class="label label-lg label-inline"
          v-bind:class="{ 'label-light-success': cp.local }"
        >
          {{ cp.local ? 'Local' : 'No local' }}
        </span>
        <v-spacer></v-spacer>

        <!-- <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on }">
                            <v-btn icon>
                                <v-icon v-on="on">mdi-pencil-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip> -->

        <v-btn icon @click="emitClick()">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn icon class="mr-2" @click="emitDelete()">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <!-- <h3 class="no-margin">{{ product.price }}€</h3>                -->
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { PROCESS_RECOGIDA } from '@/core/services/store/orders.module';
export default {
  name: 'CodPostalesCard',
  // props:{
  //     product:{
  //         type:Object,
  //         required:true
  //     }
  // },
  props: {
    cp: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitClick() {
      this.$emit('cpClicked', this.cp.code);
    },
    emitDelete() {
      this.$emit('deleteCp', this.cp.code);
    },
    processDelete(key) {
      this.$confirm(
        'Está a punto de Eliminar un Código Postal. ¿Está seguro?',
        {
          color: '#2196f3',
          title: 'Confirmar acción',
          buttonTrueText: 'Enviar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          this.$store
            .dispatch(PROCESS_RECOGIDA, this.order._id)
            .then((message) => {
              this.$emit('showMessage', message);
              this.$emit('closeModal', true);
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.no-margin {
  margin-bottom: 0;
}

.cp__title {
  font-size: 16px !important;
}

.card-width {
  max-width: 400px;
}

/* @media (min-width: 768px){
        .card-width{
            max-width: 500px;
        }
    } */

@media (min-width: 1264px) {
  .card-width {
    max-width: 360px;
  }
}
</style>
