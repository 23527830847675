<template>
  <div class="mt-10">
    <h1 class="section__headline">Códigos Postales</h1>
    <!--begin::Carta-->
    <v-container>
      <v-row class="justify-content-center">
        <div class="col-xxl-8 order-2 order-xxl-1">
          <v-card flat class="card card-custom card-stretch gutter-b">
            <v-card-title class="font-weight-bolder text-dark table__header">
              <div>Listado de códigos postales</div>
            </v-card-title>
            <div class="card-body pt-0 pb-3">
              <div>
                <v-text-field color="#FF8593" label="Filtro" required @keyup="applyFilter"></v-text-field>
              </div>
              <div class="text-right">
                <v-btn class="boton font-14 px-9 py-4 my-3" @click="addCp">
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  AÑADIR COD. POSTAL
                </v-btn>
              </div>
              <div class="text-right">
                <v-btn class="primary font-14 px-9 py-4 my-3" @click="() => modalStuart = true">
                  <v-icon class="mr-2">mdi-currency-usd</v-icon>
                  CHECK PRICE
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>

      <CodPostalesList :cpList="filteredList" @cpClicked="openCp" @deleteCp="deleteCp" />
    </v-container>
    <CodPostalesDialog :isOpen="modalCp" :isNew="isNew" @saveCp="saveCp" @newCp="newCp" @closeCp="closeCp"
      :cp="clickedCp" />
    <StuartDialog :isOpen="modalStuart" @closeStuart="() => modalStuart = !modalStuart" />

    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      {{ snackbar.text }}
      <v-btn color="black" text @click="snackbar.show = false"> Close </v-btn>
    </v-snackbar>

    <v-overlay :value="isFetching">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  UPDATE_CP,
  DELETE_CP,
  ADD_CP,
  GET_SELLER,
} from '@/core/services/store/auth.module';
import CodPostalesDialog from '@/components/codPostales/CodPostalesDialog';
import StuartDialog from '@/components/codPostales/StuartDialog';
import CodPostalesList from '@/components/codPostales/CodPostalesList';
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";

export default {
  name: 'Pedidos',
  components: {
    CodPostalesList,
    CodPostalesDialog,
    StuartDialog
  },
  data() {
    return {
      filteredList: this.getZipCodes,
      modalCp: false,
      modalStuart: false,
      clickedCp: {},
      isNew: false,
      snackbar: {
        show: false,
        text: '',
      },
    };
  },
  mounted() {
    this.filteredList = this.getZipCodes;
    console.log(this.$store.state.auth.user.seller._id);
    // this.$store.dispatch(GET_SELLER, this.$store.state.auth.user.seller._id);
  },
  computed: {
    ...mapGetters(['getZipCodes']),
    ...mapState({
      isFetching: (state) => state.auth.loading,
    }),
  },
  methods: {
    showSnackbar(msg) {
      this.snackbar.text = msg;
      this.snackbar.show = true;
    },
    applyFilter(e) {
      if (e.target.value.length === 0) {
        this.filteredList = this.getZipCodes;
      } else {
        this.filteredList = this.getZipCodes.filter((item) => {
          return item.code.includes(e.target.value);
          // item.name.toUpperCase().contains(e.target.value)
        });
      }
    },
    openCp(cp) {
      //Localizo el index del producto, y envío a la ventana de dialogo dicho producto por su index
      this.clickedCp = {
        ...this.getZipCodes.find((element) => element.code === cp),
      };
      this.modalCp = true;
      this.isNew = false;
      // this.$socket.emit('test', 'JGil')
      // console.log(productId)
    },
    addCp() {
      //Localizo el index del producto, y envío a la ventana de dialogo dicho producto por su index
      this.clickedCp = {};
      this.isNew = true;
      this.modalCp = true;
      this.filteredList = this.getZipCodes;
      // this.$socket.emit('test', 'JGil')
      // console.log(productId)
    },
    saveCp(cp) {
      this.$store.dispatch(UPDATE_CP, cp).then((data) => {
        this.showSnackbar('Código postal guardado con éxito');
        this.modalCp = false;
        this.filteredList = this.getZipCodes;
      });
    },
    newCp(cp) {
      this.$store.dispatch(ADD_CP, cp).then((data) => {
        this.showSnackbar('Código postal guardado con éxito');
        this.modalCp = false;
        this.filteredList = this.getZipCodes;
      });
    },
    closeCp() {
      this.modalCp = !this.modalCp;
    },
    deleteCp(cp) {
      this.$confirm(
        'Está a punto de eliminar el Código postal. ¿Está seguro de que desea hacerlo?',
        {
          color: '#2196f3',
          icon: '',
          title: 'Confirmar acción',
          buttonTrueText: 'Eliminar',
          buttonFalseText: 'Cancelar',
        }
      ).then((res) => {
        if (res) {
          this.$store.dispatch(DELETE_CP, cp).then((message) => {
            this.filteredList = this.getZipCodes;
            this.showSnackbar('Código postal eliminado con éxito');
            this.modalCp = false;
          });
        }
      });
    },
  },
};
</script>
<style>
.timeline-item {
  min-height: 28px !important;
}
</style>
