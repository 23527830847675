<template>
  <div>
    <v-dialog v-model="isOpen" persistent max-width="356px" :overlay-opacity="0.9" overlay-color="#17324D">
      <div class="closeIcon">
        <v-btn icon @click="closeCp" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card>
        <v-card-text>
          <v-container class="cont">
            <!-- <div class="card__title" >                          
                    {{ product.name }}                      
                </div>  
                 <div class="card__description" >                          
                    {{ product.description }}                      
                </div>   -->
            <form>
              <div>
                <div class="card__title">Código Postal</div>

                <v-text-field dense autofocus v-model="newCp.code" outlined label="Cod. Postal" style="min-height: 25px"
                  rows="4" row-height="30" class="mt-4" :error-messages="newCpCodeErrors" @blur="$v.newCp.code.$touch()"
                  @input="$v.newCp.code.$touch()"></v-text-field>
              </div>

              <div>
                <div class="card__title">Coste envío</div>

                <v-text-field dense v-model="newCp.price" outlined label="Coste envío" style="min-height: 25px"
                  :error-messages="newCpPriceErrors" @blur="$v.newCp.price.$touch()" @input="$v.newCp.price.$touch()"
                  rows="4" row-height="30" class="mt-4"></v-text-field>
              </div>

              <div>
                <div class="card__title">Envío mínimo</div>

                <v-text-field dense v-model="newCp.minPrice" outlined label="Envío mínimo" style="min-height: 25px"
                  rows="4" row-height="30" class="mt-4" :error-messages="newCpMinPriceErrors"
                  @blur="$v.newCp.MinPrice.$touch()" @input="$v.newCp.MinPrice.$touch()"></v-text-field>
              </div>

              <div>
                <div class="card__title">Precio local</div>

                <v-text-field dense v-model="newCp.localPrice" outlined label="Precio local" style="min-height: 25px"
                  rows="4" row-height="30" class="mt-4" :error-messages="newCpLocalPriceErrors"
                  @blur="$v.newCp.LocalPrice.$touch()" @input="$v.newCp.LocalPrice.$touch()"></v-text-field>
              </div>

              <div>
                <div class="card__title">Local</div>
                <v-switch :input-value="newCp.local" @change="changeLocal" />
              </div>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-col cols="4" class="mx-2">
            <v-btn class="boton" @click="saveCp()">GUARDAR</v-btn>
          </v-col>

          <!-- <v-btn color="blue darken-1" text @click="isOpen = false">Save</v-btn> -->
        </v-card-actions>
      </v-card>

      <v-overlay :value="isFetching">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  required,
  decimal,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators';
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    cp: {
      type: Object,
      required: false,
      // default: ()=>{return {sizes:[]}}
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  validations: {
    newCp: {
      code: { required, maxLength: maxLength(5), numeric },
      price: { required, decimal },
      minPrice: { required, decimal },
      localPrice: { required, decimal },
    },
  },
  data() {
    return {
      dialog: false,
      newCp: {},
    };
  },
  created() {
    console.log(this.cp);
  },
  updated() {
    this.newCp = this.cp;
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.auth.loading,
    }),
    newCpCodeErrors() {
      const errors = [];
      if (!this.$v.newCp.code.$dirty) return errors;
      !this.$v.newCp.code.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newCp.code.numeric &&
        errors.push('Debes introducir un valor numérico.');
      return errors;
    },
    newCpPriceErrors() {
      const errors = [];
      if (!this.$v.newCp.price.$dirty) return errors;
      !this.$v.newCp.price.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newCp.price.decimal &&
        errors.push('Debes introducir un valor numérico.');
      return errors;
    },
    newCpMinPriceErrors() {
      const errors = [];
      if (!this.$v.newCp.minPrice.$dirty) return errors;
      !this.$v.newCp.minPrice.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newCp.minPrice.decimal &&
        errors.push('Debes introducir un valor numérico.');
      return errors;
    },
    newCpLocalPriceErrors() {
      const errors = [];
      if (!this.$v.newCp.localPrice.$dirty) return errors;
      !this.$v.newCp.localPrice.required &&
        errors.push('Es necesario introducir un precio.');
      !this.$v.newCp.localPrice.decimal &&
        errors.push('Debes introducir un valor numérico.');
      return errors;
    },
  },
  methods: {
    clickHandler() {
      this.$emit('closeModal', true);
    },
    closeCp() {
      this.$emit('closeCp');
    },
    changeLocal() {
      this.cp.local = !this.cp.local;
    },
    saveCp() {
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
        // let newCp = {
        //   code: this.cp.code,
        //   price: Number(this.cp.price),
        //   minPrice: Number(this.cp.minPrice),
        //   localPrice: Number(this.cp.localPrice),
        //   local: this.cp.local ? true : false,
        // };
        const formatted = { ...this.newCp, price: parseFloat(this.newCp.price), minPrice: parseFloat(this.newCp.minPrice), localPrice: parseFloat(this.newCp.localPrice) }
        if (this.isNew) {
          this.$emit('newCp', formatted);
        } else {
          debugger;
          this.$emit('saveCp', formatted);
        }
      }
    },
  },
};
</script>
<style>
.closeIcon {
  position: absolute;
  top: 70px;
  width: 400px !important;
  justify-content: end;
  text-align: end;
}

.card__title {
  display: block;
  padding-top: 4px;
  font-size: 16px !important;
  font-weight: 700;
  color: #333333;
}

.card__description {
  display: block;
  padding-top: 4px;
  font-size: 12px !important;
  font-weight: 400;
  color: #808080;
}

.cont {
  padding-right: 0.3rem !important;
  padding-left: 0.3rem !important;
}

.top__chips {
  max-height: 29px;
  font-size: 14px !important;
  font-weight: 300;
}

/* Clases de vuetify para controles radiobutton */
.mdi-radiobox-blank {
  font-size: 16px !important;
}

.v-input--selection-controls__input label {
  font-size: 12px !important;
}

.v-input--selection-controls__input+.v-label {
  font-size: 12px !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: 18px !important;
  position: absolute;
  transition: inherit;
  width: 18px !important;
  left: -4px;
  top: calc(50% - 16px) !important;
  margin: 7px;
  bottom: 1px !important;
  left: -4px !important;
  right: -7px !important;
  top: -4px !important;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}
</style>
