<template>
    
    <div class="cp-list">
        <CodPostalesCard v-for="(element) in cpList" :key="element._id" :cp="element" @cpClicked="emitClick" @deleteCp="deleteCp"/>        
    </div>


</template>

<script>
import CodPostalesCard from '@/components/codPostales/CodPostalesCard'
import { mapState,mapGetters } from 'vuex'
export default {
    name: 'CodPostalestList',
    components:{
        CodPostalesCard
    },
    props:{
        cpList:{
            type: Array,
            required: false
        },
    },
    methods:{
        emitClick(cp){      
            this.$emit('cpClicked', cp)      
        },
        deleteCp(cp){      
            this.$emit('deleteCp', cp)      
        }
    }
}
</script>

<style scoped>

    .cp-list{
        display:flex;
        justify-content: space-around;
        flex-wrap: wrap;        
    }

        @media (max-width: 767px){
            .cp-list{
                justify-content: center;
            }
        }

</style>