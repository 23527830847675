<template>
    <div>
        <v-dialog eager v-model="isOpen" persistent max-width="356px" :overlay-opacity="0.9" overlay-color="#17324D">
            <div class="closeIcon">
                <v-btn icon @click="close" color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <v-card>
                <v-card-text>
                    <v-container class="cont">
                        <!-- <div class="card__title" >                          
                      {{ product.name }}                      
                  </div>  
                   <div class="card__description" >                          
                      {{ product.description }}                      
                  </div>   -->
                        <form>
                            <div>
                                <div class="card__title">Tipo de vía</div>

                                <v-text-field dense autofocus v-model="request.trackType" outlined label="Tipo de vía"
                                    style="min-height: 25px" rows="4" row-height="30" class="mt-4"></v-text-field>
                            </div>

                            <div>
                                <div class="card__title">Dirección</div>

                                <v-text-field dense v-model="request.address" outlined label="Dirección"
                                    style="min-height: 25px" rows="4" row-height="30" class="mt-4"></v-text-field>
                            </div>

                            <div>
                                <div class="card__title">Cod postal</div>

                                <v-text-field dense v-model="request.zipCode" outlined label="Cod postal"
                                    style="min-height: 25px" rows="4" row-height="30" class="mt-4"></v-text-field>
                            </div>
                        </form>
                    </v-container>
                    <div v-if="price" class="card__title justify-center"> Precio: {{ price }} </div>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-col cols="4">
                        <v-btn class="boton" :loading="isFetching" @click="check">CONSULTAR</v-btn>
                    </v-col>
                    <!-- <v-btn color="blue darken-1" text @click="isOpen = false">Save</v-btn> -->
                </v-card-actions>

            </v-card>

            <v-overlay :value="isFetching">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import monoApi from '@/core/services/monoapi.service';

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            price: null,
            isFetching: false,
            request: {},
        };
    },
    created() {
        console.log(this.cp);
    },
    updated() {
        this.newCp = this.cp;
    },
    methods: {
        check() {
            this.price = null
            this.isFetching = true
            monoApi.post('stuart/checkAddressPrice/', { address: { ...this.request } })
                .then((res) => {
                    console.log(res)
                    debugger;
                    this.price = res.data.price
                    this.isFetching = false
                })
                .catch((res) => {
                    this.price = null
                    this.isFetching = false
                    console.log(res)
                })
        },
        clickHandler() {
            this.$emit('closeModal', true);
        },
        close() {
            this.$emit('closeStuart');
        },
        saveCp() {
            this.$v.$touch();
            if (this.$v.$invalid) {
            } else {
                // let newCp = {
                //   code: this.cp.code,
                //   price: Number(this.cp.price),
                //   minPrice: Number(this.cp.minPrice),
                //   localPrice: Number(this.cp.localPrice),
                //   local: this.cp.local ? true : false,
                // };
                if (this.isNew) {
                    debugger;
                    this.$emit('newCp', this.newCp);
                } else {
                    debugger;
                    this.$emit('saveCp', this.newCp);
                }
            }
        },
    },
};
</script>
<style>
.closeIcon {
    position: absolute;
    top: 70px;
    width: 400px !important;
    justify-content: end;
    text-align: end;
}

.card__title {
    display: block;
    padding-top: 4px;
    font-size: 16px !important;
    font-weight: 700;
    color: #333333;
}

.card__description {
    display: block;
    padding-top: 4px;
    font-size: 12px !important;
    font-weight: 400;
    color: #808080;
}

.cont {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
}

.top__chips {
    max-height: 29px;
    font-size: 14px !important;
    font-weight: 300;
}

/* Clases de vuetify para controles radiobutton */
.mdi-radiobox-blank {
    font-size: 16px !important;
}

.v-input--selection-controls__input label {
    font-size: 12px !important;
}

.v-input--selection-controls__input+.v-label {
    font-size: 12px !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.v-input--selection-controls__ripple {
    border-radius: 50%;
    cursor: pointer;
    height: 18px !important;
    position: absolute;
    transition: inherit;
    width: 18px !important;
    left: -4px;
    top: calc(50% - 16px) !important;
    margin: 7px;
    bottom: 1px !important;
    left: -4px !important;
    right: -7px !important;
    top: -4px !important;
}

.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 4px;
}
</style>
  